import { v4 as uuid } from "uuid";

import {
  GET_ALL_CATEGORIES,
  GET_ALL_COUNTRIES,
  GET_ALL_LANGS,
  GET_ALL_PARTNERS,
  GET_ALL_RESELLERS,
  GET_ALL_SOCIETIES,
  GET_ALL_STAFF,
  REMOVE_TOASTER_ITEM,
  SET_ACTIVE_RESELLER_ID,
  SET_ACTIVE_SOCIETY_ID,
  SET_TOASTER,
} from "./types";

const initialState = {
  partners: [],
  resellers: [],
  societies: [],
  categories: [],
  countries: [],
  langs: [],
  staff: [],
  activeSocietyId: 4,
  activeResellerId: 3,
  toaster: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_ALL_PARTNERS:
    return {
      ...state,
      partners: action.values,
    };
  case GET_ALL_SOCIETIES:
    return {
      ...state,
      societies: action.values,
    };
  case GET_ALL_RESELLERS:
    return {
      ...state,
      resellers: action.values,
    };
  case GET_ALL_CATEGORIES:
    return {
      ...state,
      categories: action.values,
    };
  case GET_ALL_COUNTRIES:
    return {
      ...state,
      countries: action.values,
    };
  case GET_ALL_LANGS:
    return {
      ...state,
      langs: action.values,
    };
  case GET_ALL_STAFF:
    return {
      ...state,
      staff: action.values,
    };
  case SET_ACTIVE_RESELLER_ID:
    return {
      ...state,
      activeResellerId: action.values,
    };
  case SET_ACTIVE_SOCIETY_ID:
    return {
      ...state,
      activeSocietyId: action.values,
    };
  case SET_TOASTER:
    return {
      ...state,
      toaster: [
        ...state.toaster,
        {
          ...action.values,
          uid: uuid(),
        },
      ],
    };
  case REMOVE_TOASTER_ITEM:
    return {
      ...state,
      toaster: [
        ...state.toaster.filter(({ uid }) => {
          return uid !== action.values.uid;
        }),
      ],
    };
  default:
    return state;
  }
};

export default globalReducer;
