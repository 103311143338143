import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineReducers } from "redux";

import Auth from "../pages/auth/reducer";
import Global from "./global/reducer";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

export default combineReducers({
  Auth,
  Global,
  router: connectRouter(history),
});
