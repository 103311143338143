import "react-phone-number-input/style.css";

import React from "react";
import { connect } from "react-redux";

import Router from "./router";

const App = ({ auth }) => {
  return (
    <Router
      indexRedirect={auth?.isLogged ? "/tableau-de-bord" : "/connexion"}
    />
  );
};

function mapStateToProps(store) {
  return {
    auth: store.Auth,
  };
}

export default connect(mapStateToProps)(App);
