import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFound from '../pages/dumb/NotFound';
import Loader from '../sharedComponents/atoms/Loader';
import routes from './config';
import ErrorBound from './ErrorBound';

const LoadingLazyPage = <Loader active={true} />;

const LoginPage = React.lazy(() => import('../pages/auth'));

let PrivateRoute = ({ isLogged, component: Component, ...rest }) => {
  let routeRender = (props) => <Component {...props} />;
  if (!isLogged || !window.localStorage.getItem('fp_bo_token')) {
    routeRender = (props) => (
      <Redirect
        to={{
          pathname: '/connexion',
          state: { from: props.location },
        }}
      />
    );
  }
  return <Route {...rest} render={routeRender} />;
};

PrivateRoute = connect((store) => ({
  isLogged: store.Auth?.isLogged,
}))(PrivateRoute);

const Router = ({ indexRedirect, location }) => {
  return (
    <ErrorBound>
      <React.Suspense fallback={LoadingLazyPage}>
        <Switch location={location}>
          <Redirect exact from="/" to={indexRedirect} />
          <Route exact path="/connexion" component={LoginPage} />

          {Object.keys(routes).map((key) => {
            let pushRoutes = [];
            if (routes[key].Components) {
              if (routes[key].list) {
                pushRoutes.push(
                  <PrivateRoute
                    exact
                    path={routes[key].list}
                    component={routes[key].Components.list}
                  />,
                );
              }
              if (routes[key].edit) {
                pushRoutes.push(
                  <PrivateRoute
                    exact
                    path={routes[key].edit}
                    component={routes[key].Components.edit}
                  />,
                );
              }
            }

            return pushRoutes;
          })}
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    </ErrorBound>
  );
};

export default connect(({ router }) => ({ location: router.location }))(Router);
