import React from 'react';

import { Ghost } from 'react-kawaii';
import { Container, Col, Row } from 'react-bootstrap';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Container
          fluid
          className="w-100 vh-100 d-flex justify-content-center align-items-center"
        >
          <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center">
              <Ghost size={300} mood="sad" color="#E0E4E8" />
              <h1>⸘ <kbd>Ctrl</kbd>+<kbd>Maj</kbd>+<kbd>R</kbd> peut être ‽</h1>
              <pre></pre>
            </Col>
          </Row>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
