import React from 'react';
import { File } from 'react-kawaii';
import { Container, Col, Row } from 'react-bootstrap';

const NotFound = () => (
  <Container
    fluid
    className="w-100 vh-100 d-flex justify-content-center align-items-center"
  >
    <Row>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <File size={300} mood="ko" color="#F59A8E" />
        <h1>Cette page… n'existe absolument… PAS !</h1>
      </Col>
    </Row>
  </Container>
);

export default NotFound;
