import { IS_LOGGED, SET_USER } from "./types";

const initialState = {
  user: {},
  isLogged: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_USER:
    return { ...state, user: action.values };

  case IS_LOGGED:
    return { ...state, isLogged: action.value };

  default:
    return state;
  }
};

export default authReducer;
