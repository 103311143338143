export const GET_ALL_RESELLERS = "get_all_resellers";
export const GET_ALL_SOCIETIES = "get_all_societies";
export const GET_ALL_COUNTRIES = "get_all_countries";
export const GET_ALL_LANGS = "get_all_langs";
export const GET_ALL_CATEGORIES = "get_all_categories";
export const GET_ALL_PARTNERS = "get_all_partners";
export const GET_ALL_STAFF = "get_all_staff";
export const SET_ACTIVE_RESELLER_ID = "set_active_reseller_id";
export const SET_ACTIVE_SOCIETY_ID = "set_active_society_id";
export const SET_TOASTER = "set_toaster";
export const REMOVE_TOASTER_ITEM = "remove_toaster_item";
