import React from 'react';
import PropTypes from 'prop-types';

import { Spinner, Container, Row, Col } from 'react-bootstrap';

const Loader = ({ active, extraClass }) => {
  if (!active) {
    return null;
  }

  return (
    <Container
      className={`loader ${extraClass} d-flex justify-content-center align-items-center`}
    >
      <Row>
        <Col xs={12} className="loader-content">
          <Spinner animation="border" role="status" />
          <div className="sr-only">Chargement des données…</div>
        </Col>
      </Row>
    </Container>
  );
};

Loader.propTypes = {
  active: PropTypes.bool,
  extraClass: PropTypes.string,
};

export default Loader;
